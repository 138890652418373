import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "space-y-8"
}
const _hoisted_2 = { class: "grid grid-cols-2 gap-6" }
const _hoisted_3 = { class: "grid grid-cols-2 gap-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CardHeader = _resolveComponent("CardHeader")!
  const _component_BaseTextInput = _resolveComponent("BaseTextInput")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_BaseCard = _resolveComponent("BaseCard")!
  const _component_DeviceKey = _resolveComponent("DeviceKey")!
  const _component_ActionModal = _resolveComponent("ActionModal")!
  const _directive_allow = _resolveDirective("allow")!

  return (!_ctx.loading && _ctx.deviceModel)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_BaseCard, { class: "space-y-8" }, {
          default: _withCtx(() => [
            _createVNode(_component_CardHeader, {
              title: _ctx.$t('platform.device.edit'),
              onBack: _ctx.cancel
            }, null, 8, ["title", "onBack"]),
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_BaseTextInput, {
                modelValue: _ctx.deviceModel.manufacturer,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.deviceModel.manufacturer) = $event)),
                "data-dusk": "manufacturer-input",
                label: _ctx.$t('platform.device.manufacturer'),
                error: _ctx.errors.manufacturer ? _ctx.errors.manufacturer[0] : null,
                readonly: ""
              }, null, 8, ["modelValue", "label", "error"]),
              _createVNode(_component_BaseTextInput, {
                modelValue: _ctx.deviceModel.modelName,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.deviceModel.modelName) = $event)),
                "data-dusk": "model-input",
                label: _ctx.$t('platform.device.model'),
                error: _ctx.errors.modelName ? _ctx.errors.modelName[0] : null,
                readonly: ""
              }, null, 8, ["modelValue", "label", "error"])
            ]),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_BaseTextInput, {
                modelValue: _ctx.serialNumber,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.serialNumber) = $event)),
                "data-dusk": "serial-number-input",
                label: _ctx.$t('platform.device.serial-number'),
                placeholder: _ctx.$t('platform.device.enter-serial-number'),
                "is-optional": "",
                error: _ctx.errors.serialNumber ? _ctx.errors.serialNumber[0] : null
              }, null, 8, ["modelValue", "label", "placeholder", "error"]),
              _createVNode(_component_BaseTextInput, {
                modelValue: _ctx.customLabel,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.customLabel) = $event)),
                "data-dusk": "custom-label-input",
                label: _ctx.$t('platform.device.custom-label'),
                placeholder: _ctx.$t('platform.device.enter-custom-label'),
                "is-optional": "",
                error: _ctx.errors.customLabel ? _ctx.errors.customLabel[0] : null
              }, null, 8, ["modelValue", "label", "placeholder", "error"])
            ]),
            _createElementVNode("div", null, [
              _withDirectives((_openBlock(), _createBlock(_component_BaseButton, {
                "data-dusk": "save-changes-button",
                loading: _ctx.saving,
                onClick: _ctx.save
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('platform.common.save-changes')), 1)
                ]),
                _: 1
              }, 8, ["loading", "onClick"])), [
                [_directive_allow, 'device:update']
              ]),
              _createVNode(_component_BaseButton, {
                "data-dusk": "cancel-button",
                color: "ghost",
                class: "ml-2",
                disabled: _ctx.saving,
                onClick: _ctx.cancel
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('platform.common.cancel')), 1)
                ]),
                _: 1
              }, 8, ["disabled", "onClick"])
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_DeviceKey, {
          "device-id": _ctx.deviceId,
          "organisation-id": _ctx.organisationId,
          onDownload: _cache[4] || (_cache[4] = ($event: any) => (_ctx.downloadModal = true))
        }, null, 8, ["device-id", "organisation-id"]),
        (_ctx.downloadModal)
          ? (_openBlock(), _createBlock(_component_ActionModal, {
              key: 0,
              "data-dusk": "download-modal",
              title: _ctx.$t('platform.device.download'),
              body: _ctx.deviceInstructionText,
              icon: "bpm",
              actions: _ctx.downloadModalActions,
              onClose: _cache[5] || (_cache[5] = ($event: any) => (_ctx.downloadModal = false))
            }, null, 8, ["title", "body", "actions"]))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}