
import { Vue, Options } from 'vue-class-component';
import axios, { CancelTokenSource } from 'axios';
import { OrganisationDeviceService } from '@/services/api';
import { Device, DeviceModel } from '@/models';
import { IModalAction } from '@/lib';
import {
  BaseTextInput,
  BaseButton,
  BaseIcon,
  ActionModal,
  DeviceKey,
  BasePopover,
  CardHeader,
  BaseCard,
  PopoverButton
} from '@/lib/components';
import { useNotificationStore } from '@/stores/notification.store';

@Options({
  props: {
    deviceId: {
      type: String,
      required: true
    }
  },
  components: {
    PopoverButton,
    CardHeader,
    BaseCard,
    DeviceKey,
    ActionModal,
    BaseTextInput,
    BaseButton,
    BaseIcon,
    BasePopover
  }
})
export default class DevicePage extends Vue {
  deviceId!: string;
  serialNumber = '';
  customLabel = '';
  deviceInstructionText = '';
  deviceModel: DeviceModel | null = null;
  installerUrl = '';
  instructionFileUrl = '';
  saving = false;
  loading = true;
  errors: { [key: string]: Array<string> } = {};
  downloadModal = false;
  device: Device | null = null;
  request: CancelTokenSource | null = null;
  notificationStore = useNotificationStore();

  get deviceService(): OrganisationDeviceService {
    return new OrganisationDeviceService(this.organisationId);
  }

  get downloadModalActions(): Array<IModalAction> {
    return [
      {
        label: this.$t('platform.common.download') as string,
        color: 'primary',
        onClick: () => this.downloadInstaller()
      },
      {
        label: this.$t('platform.common.cancel') as string,
        color: 'ghost',
        onClick: () => (this.downloadModal = false)
      }
    ];
  }

  get organisationId(): string {
    return this.$route.params.organisationId;
  }

  beforeCreate() {
    this.deviceInstructionText = this.$t('platform.device.download-instructions') as string;
  }

  created() {
    if (!this.$route.meta?.isAdmin) {
      this.$watch('organisationId', () => {
        this.$router.push({ name: 'settings-devices', params: { organisationId: this.organisationId } });
      });
    }
    this.fetchData();
  }

  unmounted() {
    if (this.request) {
      this.request.cancel();
    }
  }

  async fetchData() {
    try {
      this.request = axios.CancelToken.source();
      const device = await this.deviceService.fetch(this.deviceId, {
        cancelToken: this.request.token
      });
      this.device = device;
      this.customLabel = device.customLabel;
      this.serialNumber = device.serialNumber;
      this.deviceModel = device.deviceModel;
      this.installerUrl = device.deviceModel.installerUrl;

      if (device.deviceModel.instructionsText) {
        this.deviceInstructionText = device.deviceModel.instructionsText;
      }

      if (device.deviceModel.instructionsUrl) {
        this.instructionFileUrl = device.deviceModel.instructionsUrl;
      }
    } catch (e) {
      if (!axios.isCancel(e)) {
        this.notificationStore.addErrorNotification({
          title: this.$t('platform.device.fetch-one-error') as string
        });
      }
    } finally {
      this.loading = false;
      this.request = null;
    }
  }

  async cancel() {
    await this.$router.push({
      name: this.$route.meta?.isAdmin ? 'domain-admin-devices' : 'settings-devices',
      params: { organisationId: this.organisationId }
    });
  }

  async save() {
    // We can only update serialNumber and customBaseLabel
    try {
      this.saving = true;
      this.errors = {};
      const data = {
        serialNumber: this.serialNumber,
        customLabel: this.customLabel
      };
      await this.deviceService.update(this.deviceId, data);
      this.notificationStore.addSuccessNotification({
        title: this.$t('platform.device.update-success')
      });
    } catch (error) {
      this.errors = error.response.data.errors;
      this.notificationStore.addErrorNotification({
        title: this.$t('platform.device.update-error')
      });
    } finally {
      this.saving = false;
    }
  }

  downloadInstaller() {
    this.deviceService.download(this.installerUrl);
    if (this.instructionFileUrl) {
      this.deviceService.download(this.instructionFileUrl, true);
    }
  }
}
